<template>
  <div class="report_w">
    <!-- 汇报列表头部 -->
    <van-sticky :offset-top="0">
      <van-nav-bar :title="title" left-text left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>今天完成的工作</h4>
      </div>
      <van-field
        v-model="reportParams.completed_work"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入"
      />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>未完成的工作</h4>
      </div>
      <van-field
        v-model="reportParams.incomplete_work"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入"
      />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>明天的计划</h4>
      </div>
      <van-field v-model="reportParams.plan" rows="3" autosize type="textarea" placeholder="请输入" />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>工作心得</h4>
      </div>
      <van-field
        v-model="reportParams.experience"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入"
      />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>备注</h4>
      </div>
      <van-field v-model="reportParams.remark" rows="3" autosize type="textarea" placeholder="请输入" />
    </div>
    <div style="background:#fff;margin-top:12px">
      <div class="report_content">
        <h4>抄送部门</h4>
      </div>
      <div class="select" @click="select()">
        <span v-if="!selectText">请选择</span>
        <span v-if="selectText">{{selectText}}</span>
        <van-icon name="arrow" />
      </div>
      <van-popup v-model="show" round position="bottom" :style="{ height: '30%' }">
        <van-picker
          v-if="show"
          title="部门"
          value-key="name"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
        ></van-picker>
      </van-popup>
    </div>
    <div style="height:12px;background:#ebeff3"></div>
    <div style="background:#fff">
      <div class="report_content">
        <h4>抄送人</h4>
      </div>
      <div class="select" @click="select_role()">
        <span v-if="!selectRole">请选择</span>
        <span v-if="selectRole">{{selectRole}}</span>
        <van-icon name="arrow" />
      </div>
      <van-popup v-model="showRole" round position="bottom" :style="{ height: '30%' }">
        <van-picker
          v-if="showRole"
          title="人员"
          show-toolbar
          value-key="true_name"
          :columns="columns_role"
          @confirm="onConfirm_role"
          @cancel="onCancel_role"
        ></van-picker>
      </van-popup>
    </div>
    <!-- 提交保存按钮 -->
    <div class="submit">
      <van-button class="sub_sure" type="info" @click="onsubmit()">提交保存</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data () {
    return {
      selectText: '',//部门
      selectRole: '',//人员
      selectid: '',//部门id
      show: false,
      showRole: false,
      columns: [],
      columns_role: [],
      title: "", //汇报列表头部
      reportParams: {
        type: '',
        cc_id: '',
        completed_work: '',//今天完成的工作
        incomplete_work: '',//未完成的工作
        plan: "",//明天计划
        experience: "",//工作心得
        remark: "",//备注
      }
    }
  },
  created () {
    if (this.$route.query.item.id) {
      this.view_report()
      if (this.$route.query.item.type == 1) {
        this.title = '日报'
      } else if (this.$route.query.item.type == 2) {
        this.title = '周报'
      } else if (this.$route.query.item.type == 3) {
        this.title = '月报'
      }
    } else {
      this.title = this.$route.query.item.span
      this.reportParams.type = this.$route.query.item.type
    }
  },
  methods: {
    view_report () {
      this.axios.get('/api/report/view', {
        params: {
          id: this.$route.query.item.id
        }
      }).then((res) => {
        this.reportParams = res.data
        this.selectText = res.data.organization_name
        this.selectRole = res.data.true_name
      })
    },
    onsubmit () {
      if (this.selectText && this.selectRole) {
        this.axios.post('/api/report/store', this.reportParams).then((res) => {
          Toast.success(res.message)
          this.$router.push('/phone/work_bench/report_list')
        })
      } else {
        Toast('请完善抄送部门或者抄送人')
      }
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    //选择部门
    select () {
      this.show = true,
        this.axios.get('/api/organization/select_organization').then((res) => {
          console.log(res.data, 'data')
          this.columns = res.data
        })
    },
    //选择人员
    select_role () {
      this.showRole = true
      this.axios.get('/api/member/select_member', { params: { organization_id: this.selectid } }).then((res) => {
        console.log(res, 'renyuan ')
        this.columns_role = res.data
      })
    },
    onConfirm (picker, value, index) {
      this.selectid = picker.id
      this.selectText = picker.name
      this.show = false
    },
    onCancel () {
      this.show = false
    },
    onConfirm_role (picker, value, index) {
      this.reportParams.cc_id = picker.id
      this.selectRole = picker.true_name
      this.showRole = false
    },
    onCancel_role () {
      this.showRole = false
    },
  },
}
</script>

<style scoped>
.report_w {
  width: 100%;
  height: 100%;
  background: #ebeff3;
}
div/deep/.van-nav-bar .van-icon {
  color: #000;
}
.report_content {
  width: 92%;
  height: 50px;
  margin: 0 auto;
  overflow: hidden;
}
.report_content h4 {
  font-size: 20px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #000000;
  margin-top: 20px;
}
.select {
  color: #b9bec2;
  width: 92%;
  margin: 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submit {
  width: 100%;
  background: #ebeff3;
  text-align: center;
}
div/deep/.sub_sure {
  width: 94%;
  margin: 20px auto;
  border-radius: 24px;
}
</style>